<template>
  <div class="home-projects mlr-main">
    <div class="home-project">
      <div class="home-project-image">
        <a href="https://askfirst.me">
          <img class="home-project-image-desktop" src="~@/assets/home/projects/afm-desktop.jpg" alt="This is Rollsafely!">
          <img class="home-project-image-phone" src="~@/assets/home/projects/afm.jpg" alt="This is Rollsafely!">
        </a>
      </div>
      <div class="home-project-info">
        <h3 class="home-project-info-title">Sample project</h3>
        <div class="home-project-info-caption">Random text</div>
        <div class="home-project-info-desc">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?</div>
      </div>
    </div>
    <div class="home-project">
      <div class="home-project-image">
        <a href="https://askfirst.me">
          <img class="home-project-image-desktop" src="~@/assets/home/projects/afm-desktop.jpg" alt="This is Rollsafely!">
          <img class="home-project-image-phone" src="~@/assets/home/projects/afm.jpg" alt="This is Rollsafely!">
        </a>
      </div>
      <div class="home-project-info">
        <h3 class="home-project-info-title">Ask Me First</h3>
        <div class="home-project-info-caption">Social media platform launchpad</div>
        <div class="home-project-info-desc">Alternative social media launchpad and QNA app.</div>
      </div>
    </div>
  </div>
</template>

<script>
import animations from '@/animations/gsap'

export default {
  name: 'HomeProjects',
  mounted () {
    const items = document.querySelectorAll('.home-project')
    for (var i = 0; i < items.length; i++) {
      animations.enterFadeInY(items[i])
    }
  }
}
</script>

<style scoped>

</style>
